import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "/news/trailguide-premium-en"
      }}>{`(English version)`}</a></p>
    <br />
    <p className="text-lg leading-9">
      <p>{`Siden 2013 har Trailguide vært gratis å bruke og uten reklame. Vanligvis når en
tjeneste er gratis og uten reklame, selges brukerdata. Vi har aldri solgt brukerdata og
har ingen planer om å gjøre det.`}</p>
      <br />
      <p>{`For å kunne videreutvikle og forbedre tjenesten, lanserer vi nå Trailguide Premium –
et månedsabonnement for 50 kroner i måneden. Jeg vil gjerne fortelle litt om hvorfor vi
gjør dette og hva du får i tillegg til appen du kjenner i dag, som forblir gratis og
reklamefri.`}</p>
    </p>
    <Image src="/news/peter_bjorn_gunther.jpg" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  To glade nerder som liker seg like godt på terrengsykkelen som bak
  datamaskinen full av kode.
  <br />
  Peter Seidl (Co-founder og utvikler), Bjørn Jarle Kvande (Founder og Developer
  in Chief)
    </p>
    <p className="text-base leading-7">
      <h3>{`Løsninger for turistnæringen`}</h3>
      <p>{`I tillegg til Trailguide-appen har vi utviklet kommersielle løsninger for turistnæringen,
slik at de kan digitalisere utendørsaktiviteter for sine gjester og besøkende. Tanken var
at de som tjener på sykkelturisme skulle betale for løsningene.`}</p>
      <br />
      <h3>{`Utfordringer med salg`}</h3>
      <p>{`Selv om teknologien vår brukes av flere turistdestinasjoner per i dag, har ikke vi
(to datanerder) hatt de nødvendige salgsegenskapene til å følge opp kundene på en
tilfredsstillende måte.`}</p>
      <br />
      <h3>{`Økonomiske utfordringer`}</h3>
      <p>{`For å drifte Trailguide effektivt, er vi avhengige av eksterne tjenester som koster
penger. Økt bruk har ført til økte kostnader.`}</p>
      <br />
      <h3>{`Frivillige donasjoner`}</h3>
      <p>{`Vi har lagt til rette for frivillige donasjoner fra brukerne, noe som har dekket våre
digitale driftskostnader i tre til fire måneder. Men dette er ikke en bærekraftig
løsning for en programvarebedrift.`}</p>
      <br />
      <h3>{`Introduksjon av Trailguide Premium`}</h3>
      <p>{`Vi gjør nå om Trailguide til en såkalt freemium-tjeneste hvor mesteparten av funksjonene
og tilgang til all turinformasjon vil være gratis slik det er i dag, mens noen nye
funksjoner vil være forbeholdt de som har et premium abonnement.`}</p>
      <br />
      <h3>{`Enkel registrering`}</h3>
      <p>{`Det er enkelt å registrere seg, og du blir automatisk trukket 50 kroner i måneden.
Du kan stoppe og restarte abonnementet når som helst. Registreringen finner du under
`}<strong parentName="p">{`Min side`}</strong>{` i appen når du er logget inn.`}</p>
      <br />
      <h3>{`En overkommelig pris`}</h3>
      <p>{`50 kroner i måneden er omtrent prisen på to kroneis eller en halv øl. Vi håper dette er
en overkommelig pris for de fleste. 50 kroner i måneden fra mange nok brukere vil gjøre
en enorm forskjell for oss.`}</p>
      <br />
      <h3>{`Støttemedlemskap`}</h3>
      <p>{`Selv om du ikke trenger de nye funksjonene, er Trailguide Premium en fin måte å støtte
driften og utviklingen av appen på. Programvareutvikling er komplekst og ressurskrevende.`}</p>
      <br />
      <h3>{`Trailguide spillelister`}</h3>
      <p>{`Vi har lansert en ny `}<a parentName="p" {...{
          "href": "/news/trailguide-2024-spring-edition"
        }}>{`listefunksjon`}</a>{`, en slags
spilleliste ala Spotify. Her kan du lage egne lister med stier du liker, planlegger å
sykle, eller vil dele med andre. Mye mer funksjonalitet er planlagt, og vi håper dette
blir et verdifullt tillegg til appen. Du har fortsatt tilgang til listefunksjonen
i gratisversjonen, men den er noe begrenset.`}</p>
      <br />
      <h3>{`Bli en stolt bidragsyter`}</h3>
      <p>{`Vi oppfordrer alle som bruker Trailguide regelmessig og har funnet fantastiske turer til
å registrere seg for Trailguide Premium. Din støtte er avgjørende for at vi kan fortsette
å levere en flott tjeneste.`}</p>
    </p>
    <br />
    <br />
    <p>{`Med vennlig hilsen`}<br />{`
The Trailguide Team`}<br />{`
Bjørn Jarle Kvande`}<br />{`
Peter Seidl`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      